<template>
  <v-container
    v-if="order"
    id="order-view-content"
  >
    <transition
      name="simple-slide-03"
      appear
    >
      <order-preview-basic
        :oid="oid"
        :show-actions="false"
        :callbacks="getCallbacks(this)"
      />
    </transition>
    <transition
      name="simple-slide-03"
      appear
    >
      <v-card
        class="mx-auto mt-5 order-form-vcard"
      >
        <v-card-text class="order-form-vcard-text">
          <v-form
            id="order-form"
            ref="orderForm"
          >
            <v-item-group class="form-section customer-details">
              <div
                class="form-section-header"
                @click="customerDetailsSectionExpanded = !customerDetailsSectionExpanded"
              >
                Billing Details
                <v-icon
                  v-if="customerDetailsSectionExpanded"
                  class="float-right"
                  color="white"
                >
                  mdi-chevron-up
                </v-icon>
                <v-icon
                  v-if="!customerDetailsSectionExpanded"
                  class="float-right"
                  color="white"
                >
                  mdi-chevron-down
                </v-icon>
              </div>
              <v-expand-transition>
                <div
                  v-show="customerDetailsSectionExpanded"
                  class="form-section-body-wrapper"
                >
                  <div
                    class="form-section-body"
                  >
                    <v-item-group class="fields-group">
                      <v-text-field
                        v-model="order.ClientName"
                        label="Company Name"
                        disabled
                      />
                      <v-text-field
                        v-model="order.ClientDescription"
                        label="Company Description"
                        disabled
                      />
                    </v-item-group>
                    <v-item-group class="fields-group">
                      <v-text-field
                        v-model="order.ClientVatNo"
                        label="Vat Number"
                        disabled
                      />
                      <v-select
                        v-if="order.ClientCountry"
                        v-model="order.ClientCountry.Oid"
                        :items="getCountriesSelectOptions()"
                        item-text="label"
                        item-value="value"
                        label="Country"
                        disabled
                        validate-on-blur
                      />
                    </v-item-group>
                    <v-item-group class="fields-group">
                      <v-text-field
                        v-model="order.ClientTaxAuthority"
                        label="Tax Activity"
                        disabled
                      />
                      <v-select
                        v-if="order.ClientVatNo"
                        v-model="order.ClientVatArea"
                        :items="getVatAreasSelectOptions()"
                        item-text="label"
                        item-value="value"
                        label="Vat Area"
                        disabled
                      />
                    </v-item-group>
                    <v-item-group class="fields-group">
                      <v-text-field
                        v-model="order.ClientTelephone"
                        label="Telephone"
                        disabled
                      />
                      <v-text-field
                        v-model="order.ClientEmail"
                        label="Email"
                        disabled
                      />
                    </v-item-group>
                  </div>
                </div>
              </v-expand-transition>
            </v-item-group>
            <v-item-group class="form-section address address-from">
              <div
                class="form-section-header"
                @click="shipFromSectionExpanded = !shipFromSectionExpanded"
              >
                Ship From<v-icon
                  v-if="shipFromSectionExpanded"
                  class="float-right"
                  color="white"
                >
                  mdi-chevron-up
                </v-icon>
                <v-icon
                  v-if="!shipFromSectionExpanded"
                  class="float-right"
                  color="white"
                >
                  mdi-chevron-down
                </v-icon>
              </div>
              <v-expand-transition>
                <div
                  v-show="shipFromSectionExpanded"
                  class="form-section-body-wrapper"
                >
                  <div
                    class="form-section-body"
                  >
                    <v-item-group class="fields-group">
                      <v-text-field
                        v-model="order.AddressFrom.CompanyName"
                        label="Company Name"
                        disabled
                      />
                    </v-item-group>
                    <v-item-group class="fields-group">
                      <v-text-field
                        v-model="order.AddressFrom.AddressDetails"
                        label="Street Name/Number"
                        disabled
                      />

                      <v-text-field
                        v-model="order.AddressFrom.City"
                        label="City"
                        disabled
                      />
                    </v-item-group>
                    <v-item-group class="fields-group">
                      <v-text-field
                        v-model="order.AddressFrom.PostCode"
                        label="Postal Code"
                        disabled
                      />

                      <v-select
                        v-if="order.AddressFrom.Country"
                        v-model="order.AddressFrom.Country.Oid"
                        :items="getCountriesSelectOptions()"
                        item-text="label"
                        item-value="value"
                        label="Country"
                        disabled
                        validate-on-blur
                      />
                    </v-item-group>
                    <v-item-group class="fields-group">
                      <v-text-field
                        v-model="order.AddressFrom.ContactPerson"
                        label="Contact Person"
                        disabled
                      />

                      <v-text-field
                        v-model="order.AddressFrom.Email"
                        label="Email"
                        append-icon="mdi-email"
                        disabled
                      />

                      <v-text-field
                        v-model="order.AddressFrom.Telephone"
                        label="Telephone"
                        append-icon="mdi-phone"
                        disabled
                      />
                    </v-item-group>
                    <v-item-group class="fields-group">
                      <v-text-field
                        v-model="order.PickUpReference"
                        label="Pick Up Reference"
                        disabled
                      />
                      <v-text-field
                        v-model="order.OnBehalfOf"
                        label="On Behalf Of"
                        required
                      />
                    </v-item-group>
                    <v-item-group class="fields-group">
                      <v-text-field
                        v-model="order.ContentType"
                        label="Content Type"
                        disabled
                      />
                    </v-item-group>
                    <v-item-group class="fields-group datetime-group">
                      <time-picker
                        v-model="order.AddressFrom.WorkingHoursFrom"
                        :init-value="order.AddressFrom.WorkingHoursFrom"
                        label="Working hours From"
                        :read-only="true"
                        :force-no-opaque="true"
                      />
                      <time-picker
                        v-model="order.AddressFrom.WorkingHoursTo"
                        :init-value="order.AddressFrom.WorkingHoursTo"
                        label="Working hours To"
                        :read-only="true"
                        :force-no-opaque="true"
                      />
                      <date-picker
                        v-model="order.PickUpDate"
                        :init-value="order.PickUpDate"
                        label="Pickup Date"
                        :read-only="true"
                        :force-no-opaque="true"
                      />
                    </v-item-group>
                  </div>
                </div>
              </v-expand-transition>
            </v-item-group>
            <v-item-group class="form-section address address-to">
              <div
                class="form-section-header"
                @click="shipToSectionExpanded = !shipToSectionExpanded"
              >
                Ship To<v-icon
                  v-if="shipToSectionExpanded"
                  class="float-right"
                  color="white"
                >
                  mdi-chevron-up
                </v-icon>
                <v-icon
                  v-if="!shipToSectionExpanded"
                  class="float-right"
                  color="white"
                >
                  mdi-chevron-down
                </v-icon>
              </div>
              <v-expand-transition>
                <div
                  v-show="shipToSectionExpanded"
                  class="form-section-body-wrapper"
                >
                  <div
                    class="form-section-body"
                  >
                    <v-item-group class="fields-group">
                      <v-text-field
                        v-model="order.AddressTo.CompanyName"
                        label="Company Name"
                        disabled
                      />
                    </v-item-group>
                    <v-item-group class="fields-group">
                      <v-text-field
                        v-model="order.AddressTo.AddressDetails"
                        label="Street Name/Number"
                        disabled
                      />
                    </v-item-group>
                    <v-item-group class="fields-group">
                      <v-text-field
                        v-model="order.AddressTo.AddressDetails"
                        label="Street Name/Number"
                        disabled
                      />

                      <v-text-field
                        v-model="order.AddressTo.City"
                        label="City"
                        disabled
                      />
                    </v-item-group>
                    <v-item-group class="fields-group">
                      <v-text-field
                        v-model="order.AddressTo.PostCode"
                        label="Postal Code"
                        disabled
                      />

                      <v-select
                        v-if="order.AddressTo.Country"
                        v-model="order.AddressTo.Country.Oid"
                        :items="getCountriesSelectOptions()"
                        item-text="label"
                        item-value="value"
                        label="Country"
                        disabled
                        validate-on-blur
                      />
                    </v-item-group>
                    <v-item-group class="fields-group">
                      <v-text-field
                        v-model="order.AddressTo.ContactPerson"
                        label="Contact Person"
                        disabled
                      />

                      <v-text-field
                        v-model="order.AddressTo.Email"
                        label="Email"
                        append-icon="mdi-email"
                        disabled
                      />

                      <v-text-field
                        v-model="order.AddressTo.Telephone"
                        label="Telephone"
                        append-icon="mdi-phone"
                        disabled
                      />
                    </v-item-group>
                    <v-item-group class="fields-group datetime-group">
                      <time-picker
                        v-model="order.AddressTo.WorkingHoursFrom"
                        :init-value="order.AddressTo.WorkingHoursFrom"
                        label="Working hours From"
                        :read-only="true"
                        :force-no-opaque="true"
                      />
                      <time-picker
                        v-model="order.AddressTo.WorkingHoursTo"
                        :init-value="order.AddressTo.WorkingHoursTo"
                        label="Working hours To"
                        :read-only="true"
                        :force-no-opaque="true"
                      />
                      <date-picker
                        v-model="order.AddressFrom.PickUpDate"
                        :init-value="order.AddressFrom.PickUpDate"
                        label="Pickup Date"
                        :read-only="true"
                        :force-no-opaque="true"
                      />
                    </v-item-group>
                  </div>
                </div>
              </v-expand-transition>
            </v-item-group>
          </v-form>
        </v-card-text>
      </v-card>
    </transition>
    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="export"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :html-to-pdf-options="{ htmlToPdfOptions: { margin: 0, filename: `order.pdf`, image: { type: 'jpeg', quality: 0.98 }, enableLinks: false, html2canvas: { scale: 1, useCORS: true }, jsPDF: { unit: 'px', format: 'a4', orientation: 'portrait' }, } }"
    >
      <section slot="pdf-content">
        <div class="print-header">
          Goldair Cargo S.A.
        </div>
        <order-print
          v-if="printData"
          :print-data="printData"
        />
      </section>
    </vue-html2pdf>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import DatePicker from '@/components/Forms/Elements/DatePicker'
  import TimePicker from '@/components/Forms/Elements/TimePicker'
  import OrderPreviewBasic from '@/components/OrderPreviewBasic.vue'
  import OrderPrint from '@/components/Print/OrderPrint'
  import VueHtml2pdf from 'vue-html2pdf'
  import { helperFunctions } from '@/store/helpers'

  export default {
    name: 'OrderView',
    components: {
      OrderPreviewBasic,
      DatePicker,
      TimePicker,
      VueHtml2pdf,
      OrderPrint,
    },
    props: {
      oid: [Number, String],
    },
    data: () => ({
      customerDetailsSectionExpanded: true,
      shipFromSectionExpanded: true,
      shipToSectionExpanded: true,
      printData: null,
    }),
    computed: {
      ...mapGetters('orders', ['getOrderByOid']),
      ...mapGetters('apiGeneric', ['getCountriesSelectOptions', 'getVatAreasSelectOptions']),
      order: function () {
        return this.getOrderByOid(this.oid)
      },
    },
    created () {
      if (!this.order) {
        this.$router.push('/404')
      }
      this.order.AddressFrom.WorkingHoursFrom = helperFunctions.decodeTimeFromApi(this.order.AddressFrom.WorkingHoursFrom)
      this.order.AddressFrom.WorkingHoursTo = helperFunctions.decodeTimeFromApi(this.order.AddressFrom.WorkingHoursTo)
      this.order.PickUpDate = helperFunctions.decodeDateFromApi(this.order.PickUpDate)
      this.order.AddressTo.WorkingHoursFrom = helperFunctions.decodeTimeFromApi(this.order.AddressTo.WorkingHoursFrom)
      this.order.AddressTo.WorkingHoursTo = helperFunctions.decodeTimeFromApi(this.order.AddressTo.WorkingHoursTo)
    },
    methods: {
      getCallbacks: (self) => {
        return {
          print: () => {
            self.printData = {
              ...self.order,
            }
            self.$refs.html2Pdf.generatePdf()
          },
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
#order-view-content{
  max-width: 750px;
  .v-input--is-disabled {
    opacity: 1 !important;
  }
}
.order-form-vcard{
  background-color: rgba(255, 255, 255, 0);
  box-shadow: none !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  .order-form-vcard-text{
    padding: 0;
    .v-item-group{
      padding: 5px 0;
      &.form-section{
        background-color: $bgWhite09;
        padding: 0;
        margin-top: 5px;

        .form-section-body{
          padding: 20px;
        }

        .form-section-header{
          background-color: $goldairLightBlue2;
          padding: 5px 20px;
          box-sizing: content-box;
          color: #fff;
          font-weight: 400;
          font-size: 15px;
          cursor: pointer;
        }

        .fields-group{
          display: flex;
          &:not(.address-selection){
            .v-text-field{
              width: 50%;
              //max-width: 50%;
            }
          }
          .v-input:not(:last-child){
            margin-right: 30px;
          }
          &.address-selection{
            padding: 0 !important;
          }
          &.datetime-group{
            padding-top: 0;
            input{
              cursor: default !important;
            }
            >.row{
              padding: 0;
              margin: 0;
              .col{
                padding: 0 !important;
              }
            }
          }
        }
      }
      &.item-section{
        margin-bottom: 20px;
      }
      .address-group{
        display: flex;
        flex-direction: row;
        .v-select, .v-input:first-child{
          padding-right: 10px;
        }
      }
    }
  }
  .v-card__actions{
    padding: 20px;
    background-color: #fff;
  }
}
</style>
